
.article {
    min-width: 48%;

    &:first-child {
        margin-left: 16px;
    }

    &:nth-child(4) {
        padding-right: 16px;
    }

    &__img {
        height: calc((80vw - 15px) * 0.61);
        border-radius: 16px;
    }

    &__name {
        height: 48px;
    }

    &__title {
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.articles {
    height: calc((80vw - 15px) * 0.61 + 164px);
}

.tag {
    padding: 4px 16px;
    border-radius: 16px;
    height: 32px;
    white-space: nowrap;
}

